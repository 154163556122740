import image1 from './assets/images/5097611.jpg'
import image2 from './assets/images/linkedin.png'

function App() {
  return (
    <div className="w-screen h-screen bg-white">
      <div className="w-9/12 sm:w-1/2 h-[calc(100%_-_2rem)] m-auto text-center p-5 flex flex-col justify-center content-center">
        <p className="text-xl sm:text-4xl mb-8">Coming soon!!</p>
        <img src={image1} className="w-10/12 sm:w-1/2 self-center" />
      </div>
      <div className='text-center flex justify-center content-center'>
        <p className='mr-2'>
          Kapil Baraskar 
        </p>
        <a href='https://www.linkedin.com/in/kapil-baraskar-840676213/' alt="LinkedIn" className='pointer' target={"_blank"}>
        <img src={image2} className="w-6 self-center" />
        </a>
      </div>
    </div>
  );
}

export default App;
 